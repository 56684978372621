import React, { useState } from 'react';
import css from '../Omoss/Winvc.css'
import guide from '../Images/Settlr_Guide.pdf'
import { FaFileDownload } from 'react-icons/fa'


import vc1 from '../Images/vc1.jpg'
import vc2 from '../Images/vc2.jpg'
import vc3 from '../Images/vc3.jpg'
import vc4 from '../Images/vc4.jpg'
import vc5 from '../Images/vc5.jpg'
import vc6 from '../Images/vc6.jpg'
import vc8 from '../Images/vc8.jpg'


function Winvc() {
    const [images, setImages] = useState([



        vc4,
        vc5,
        vc6,
        vc8,

    ]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        if (currentIndex < images.length - 4) {
            setCurrentIndex(currentIndex + 1);
        } else {
            setCurrentIndex(0);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        } else {
            setCurrentIndex(images.length - 4);
        }
    };
    return (
        <div className="Winvc-container">
            <div className="Winvc-img">
            </div>
            <div className="Winvc-text">
                <p>Läs mer om Settlrs arbetet i startguiden </p>
            </div>
            <a href={guide} download><button download className='button-download'>Download <FaFileDownload size={20} style={{ color: 'white' }} /> </button></a>

            <div className="gallery1-container">
                <h1>Följ vår resa.</h1>
                <div className="gallery1-images">
                    {images.slice(currentIndex, currentIndex + 4).map((image, index) => (
                        <div key={index} className="gallery1-image">
                            <img src={image} alt={`Image ${index}`} />
                        </div>
                    ))}
                </div>
                <div className="gallery1-buttons">
                    <button onClick={handlePrev}>Prev</button>
                    <button onClick={handleNext}>Next</button>
                </div>
            </div>
        </div>
    )
}
export default Winvc