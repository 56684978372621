import React from "react";
import css from '../Solutions/Solutionbostad.css'
import SolutionBostad from '../Images/Solutionbostad.jpg'
import solutionområde from '../Images/solutionområde.svg'
import solutionfilter from '../Images/solutionfilter.svg'
import solutionai from '../Images/solutionai.svg'


function Solutionbostad() {
    return (
        <section>
            <div className="bostad-container">
                <div className="bostad-img">
                    <img src={SolutionBostad}></img>
                </div>
                <div className="bostad-text">
                    <h2>Din bostad, dina krav.</h2>



                    <div className="text-område">
                        <img src={solutionområde}></img>
                        <div>
                            <p>Favoritområde?</p>
                            <p>I appen kan du snabbt få en översikt kring vilka boenden som finns lediga i ditt favoritområde.</p>
                        </div>
                    </div>

                    <div className="text-filter">
                        <img src={solutionfilter}></img>
                        <div>
                            <p>Filter</p>
                            <p>Hur ser din budget ut? Behöver du ett extra rum? en diskmaskin? I filtret kan du själv sätta dina exakta krav.</p>
                        </div>
                    </div>

                    <div className="text-ai">
                        <img src={solutionai}></img>
                        <div>
                            <p>Vart kan jag bo?</p>
                            <p>Vet du inte vart du kan bo? Fråga då vår AI bot som snabbt och enkelt kan svara på alla dina bostadsrelaterade frågor.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Solutionbostad