import React from "react";
import css from '../Solutions/Solutionswipe.css'
import SolutionSwipe from "../Images/SolutionSwipe.jpg";

function Solutionswipe() {
    return (
        <section>
            <div className="swipe-container">
                <div className="swipe-text">
                    <h2>Svajpa fram ditt drömboende</h2>
                    <p>Vem sa att svajpning bara var för dejting? Svajpa är roligt och nu kan du äntligen svajpa för att hitta ditt drömboende.
                    </p>
                </div>
                <div className="swipe-img">
                    <img src={SolutionSwipe}></img>
                </div>
            </div>
        </section>
    )
}
export default Solutionswipe