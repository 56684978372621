import React from 'react';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import SolutionMain from './Solutions/SolutionMain';
import Solutionhow from './Solutions/Solutionhow';
import Solutionswipe from './Solutions/Solutionswipe';
import Solutionbostad from './Solutions/Solutionbostad';




function Solution() {
    return (
        <>
            <Navbar></Navbar>
            <SolutionMain></SolutionMain>
            <Solutionhow></Solutionhow>
            <Solutionswipe></Solutionswipe>
            <Solutionbostad></Solutionbostad>
            <Footer></Footer>


        </>
    )
}

export default Solution