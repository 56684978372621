import React from 'react';
import Navbar from './Components/Navbar';
import Main from './Components/Main';
import Footer from './Components/Footer';
import Gallery from './Components/Gallery';
import How from './Components/How';



function Hem() {
    return (
        <>
            <Navbar></Navbar>
            <Main></Main>
            <Gallery></Gallery>
            <How></How>
            <Footer></Footer>


        </>
    )
}

export default Hem