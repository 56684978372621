import React from "react";
import download from '../Images/download.jpg'
import fillin from '../Images/fillin.jpg'
import match from '../Images/match.jpg'
import css from '../Solutions/Solutionhow.css'
import identitetikon from '../Images/identitetikon.png'
import free from '../Images/freeicon.png'
import husikon from '../Images/husikon.png'

function Solutionhow() {
    return (
        <section className="how-bg">
            <div className="how-topic">
                <h1>Varför hyra en bostad med Settlr?</h1>
            </div>
            <div className="how-flex">
                <div className="how1">
                    <img src={identitetikon}></img>
                    <h3>Säkert och seriöst</h3>
                    <p>Hos oss är det viktigt att alla användare känner sig trygga. Därför kräver vi att alla våra användare verifierar sin identitet genom någon av våra verifieringsmetoder. Upplev en bekväm och trygg bostadsjakt med Settlr!
                    </p>
                </div>
                <div className="how2">
                    <img src={free}></img>
                    <h3>Kostandsfritt</h3>
                    <p>En bostadsapp som är helt gratis utan några dolda kostnader? Det kan vara svårt att tro, men med Settlr är det verklighet! Vi vill att bostadsjakten ska vara enkel och rolig för alla, och därför är vår tjänst helt gratis.
                    </p>
                </div>
                <div className="how3">
                    <img src={husikon}></img>
                    <h3>Allt i appen</h3>
                    <p>Är du fortfarande på jakt efter ditt drömboende? Låt Settlr göra det till verklighet! Vår app ger dig enkel och snabb tillgång till de mest eftertraktade bostäderna. Tveka inte längre, upptäck Settlr nu!
                    </p>
                </div>
            </div>
        </section>
    )

}

export default Solutionhow