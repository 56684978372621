import css from './Gallery.css'
import React, { useState, useEffect, useRef } from 'react';
import Swipevärd from '../Images/Swipvärd.png'
import Matchvärd from '../Images/Matchvärd.png'
import Gilladevärd from '../Images/Gilladevärd.png'
import Objektvärd from '../Images/Objektvärd.png'
import Boendevärd from '../Images/Boendevärd.png'
import Kortvärd from '../Images/Kortvärd.png'
import Swipegäst from '../Images/Swipegäst.png'
import Matchgäst from '../Images/Matchgäst.png'
import Gilladegäst from '../Images/Gilladegäst.png'
import Kartagäst from '../Images/Kartagäst.png'
import Chattgäst from '../Images/Chattgäst.png'
import Betyggäst from '../Images/Betyggäst.png'
import TransTest from '../Images/TransTest.png'
import left from '../Images/left.png';
import right from '../Images/right.png'





import Primärbild from '../Images/Primärbild.png'
import Sekundärbild from '../Images/Sekundärbild.png'





const Gallery = () => {

    const [selectedProduct, setSelectedProduct] = useState(null);






    const products = [
        {
            id: 1,
            image: Primärbild,
            title: 'Klicka här för bostadsägare',
            description: 'Ett roligt och interaktivt sätt att leta hyresvärdar.',
            items: [{

                image: Swipevärd,
                text: 'Som hyresvärd kommer du i förstahand bli bemött av denna vy. På denna bild ser du att det är två personer som tillsammans söker en bostad.'
            },
            {
                image: Gilladevärd,
                text: 'När du gillat en potentiell hyresgäst kommer personen att samlas i den här listan. Sedan kan erbjuda hyresgästen en bostad.'
            },
            {
                image: Matchvärd,
                text: 'Du som hyresvärd kommer att vara avgörande om ni kommer få någon kontakt. Här ser det ut när du väljer att matcha med en hyresgäst.'
            },
            {
                image: Objektvärd,
                text: 'Här får du en översikt över dina objekt. Du kan även välja att swipa på enbart en fastighetskomplex eller en specifik bostad.'
            },
            {
                image: Boendevärd,
                text: 'Om du väljer ett objekt kommer du kunna se samtliga bilder samt redigera eller lägga till nya.'
            },
            {
                image: Kortvärd,
                text: 'Här har du en ekonomiskt översikt relaterat till dina bostäder där du har möjligheten att se kostnader, intäkter eller betala fakturor.'
            },
            {
                image: TransTest,
                text: ''
            }]
        },
        {
            id: 2,
            image: Sekundärbild,
            title: 'Klicka här för bostadssökare',
            description: 'Ett roligt och interaktivt sätt att leta efter boenden.',
            items: [{
                image: Swipegäst,
                text: 'Som bostadssökande kommer du i förstahand bli bemött av denna vy. Denna bild visar en bostad samt information om storlek och kostnad.'
            },
            {
                image: Gilladegäst,
                text: 'När du gillat en potentiell bostad kommer dem att samlas i denna lista där du sedan kan ansöka till den specifika bostaden.'
            },
            {
                image: Matchgäst,
                text: 'När du ansökt till en bostad väntar vi tills hyresvärden gillar dig tillbaka och då skapas en matchning.'
            },
            {
                image: Kartagäst,
                text: 'Kartan är tillför att se vilka bostäder du ser inom ditt valda område. Du kan även här gå in och se mer information om bostaden.'
            },
            {
                image: Chattgäst,
                text: 'När en matchning väl har hänt kommer du kunna chatta med din hyresvärd om information som visning och kontrakt.'
            },
            {
                image: Betyggäst,
                text: 'När ett kontrakt väl löpt ut kommer du och hyresvärden kunna tycka till om varandra vilket leder till högre kvalité hos samtliga användare.'
            },
            {
                image: TransTest,
                text: ''
            }]
        },
    ];

    const handleClick = (productId) => {
        setSelectedProduct(productId);
        setSelectedItems([0, 1]);
    };

    const [selectedItems, setSelectedItems] = useState([0, 1]);





    return (
        <main className="screen">

            <section className='padding'>


                <div className="products">
                    {products.map((product) => (
                        <div
                            key={product.id}
                            className={`product ${product.id === selectedProduct ? "selected" : ""}`}
                            onClick={() => handleClick(product.id)}
                        >
                            <img src={product.image} alt={product.title} />
                            <h3>{product.title}</h3>
                            <p>{product.description}</p>

                        </div>
                    ))}
                </div>

                {selectedProduct && (
                    <div className="gallery-container">
                        <div className='gallery-image-container1'>
                            {selectedItems.map((selectedItem, index) => (


                                <div className='gallery-image-container'>
                                    {index === 0 && (
                                        <p className="gallery-text">
                                            {products.find((product) => product.id === selectedProduct).items[selectedItem].text}
                                        </p>
                                    )}
                                    <div key={selectedItem} className={`gallery-item gallery-item-${index}`}>

                                        <img
                                            className="gallery-image"
                                            src={products.find((product) => product.id === selectedProduct).items[selectedItem].image}
                                            alt={`Gallery Image ${selectedItem}`}
                                        />
                                    </div>


                                </div>
                            ))}
                        </div>
                        <div className="button-container">
                            <button className='button-previous'
                                onClick={() => {
                                    const prevItem1 = selectedItems[0] - 1;
                                    const prevItem2 = selectedItems[1] - 1;
                                    if (prevItem1 >= 0 && prevItem2 >= 0) {
                                        setSelectedItems([prevItem1, prevItem2]);
                                    }
                                }}
                            >
                                <img src={left}></img>
                            </button>
                            <button className='button-next' onClick={() => {
                                const nextItem1 = selectedItems[0] + 1;
                                const nextItem2 = selectedItems[1] + 1;
                                if (nextItem1 <= Math.min(6, products.find((product) => product.id === selectedProduct).items.length) && nextItem2 <= Math.min(6, products.find((product) => product.id === selectedProduct).items.length)) {
                                    setSelectedItems([nextItem1, nextItem2]);
                                }
                            }}><img src={right}></img></button>

                        </div>
                    </div>


                )}


                {!selectedProduct && (
                    <div className="placeholder">

                    </div>
                )}

            </section>
        </main>
    );
};

export default Gallery;
