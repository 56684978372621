import React from "react";
import css from '../Omoss/Values.css'

function Values() {
    return (
        <div className="Value-container">
            <div className="Value-heading">
                <h1>Våra värderingar.</h1>
            </div>
            <div className="Value-line"><hr
                style={{
                    background: 'black',
                    color: 'black',
                    borderColor: 'black',
                    height: '100%',
                    width: '2px'
                }}
            /></div>
            <div className="Value-text">
                <p>Settlr grundar sig på värderingar som prioriterar hållbarhet, engagemang, jämställdhet och nyfikenhet. Vi integrerar hållbarhet i varje aspekt av vår plattform för att gynna hyresgäster och fastighetsägare/företag samt minimera vår miljöpåverkan. Vi uppmuntrar engagemang och nyfikenhet från våra användare och söker ständigt feedback för att förbättra vår plattform.</p>
                <p>Vårt team delar våra värderingar och främjar en positiv atmosfär av innovation och nyfikenhet. Vi prioriterar långvariga relationer baserade på förtroende och ömsesidig respekt med våra kunder och partners.</p>
                <p>Sammanfattningsvis vill vi skapa en plattform som levererar värde för hyresgäster och fastighetsägare som främjar hållbarhet och en kultur av innovation och respekt.</p>
            </div>
        </div>

    )
}

export default Values