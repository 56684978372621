
import { Routes, Route } from "react-router-dom";
import Hem from './Hem';
import Kontakt from './Kontakt';
import Omoss from './Omoss';
import Solution from './Solution';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Hem />} />
      <Route path='/lösning' element={<Solution />} />
      <Route path='/omoss' element={<Omoss />} />
      <Route path='/kontakt' element={<Kontakt />} />
    </Routes>
  )
}

export default App;
