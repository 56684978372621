import React from "react"
import css from './Footer.css'
import ikonskellefteå from '../Images/ikonskellefteå.png'
import MittHemGuide from '../Images/MittHem_GuidePDF.pdf'
import ikonventurecup from '../Images/venturecup.png'
import { FaLinkedin, FaInstagramSquare, FaFacebookSquare } from 'react-icons/fa'

function Footer() {
    return (

        <footer>
            <section className="trustedby">
                <div className='icon-text'>
                    <h1> Trusted by.</h1>
                </div>
                <div className='icons' id="samarbeten">
                    <a href="https://skelleftea.se/" ><img className='icon-skellefteå' src={ikonskellefteå} ></img></a>
                    <a href="https://www.venturecup.se/en/" ><img className="icon-vanturecup" src={ikonventurecup}></img></a>
                </div>
            </section>
            <p>&copy; Settlr AB. All rights reserved.</p>
            <nav>
                <ul className="Footer-icon" id="bottom">
                    <li><a href="https://www.linkedin.com/company/92549666/admin/?feedType=following" target="_blank"><FaLinkedin size={30} /></a></li>
                    {/* <li><a href="#"><FaInstagramSquare size={30} /></a></li> */}
                    <li><a href="https://www.facebook.com/profile.php?id=100091260438495" target="_blank"><FaFacebookSquare size={30} /></a></li>
                </ul>
            </nav>
        </footer>
    )
}

export default Footer