import React from 'react';
import Navbar from './Components/Navbar';

import Footer from './Components/Footer';
import MainAbout from './Omoss/MainAbout';
import Values from './Omoss/Values';
import Sustainable from './Omoss/Sustainable';
import Winvc from './Omoss/Winvc';



function Omoss() {
    return (
        <>
            <Navbar></Navbar>
            <MainAbout></MainAbout>
            <Values></Values>
            <Sustainable></Sustainable>
            <Winvc></Winvc>
            <Footer></Footer>



        </>
    )
}

export default Omoss