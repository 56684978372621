import React from 'react';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import MainContact from './Contact/MainContact';


function Kontakt() {
    return (
        <>
            <Navbar></Navbar>
            <MainContact></MainContact>
            <Footer></Footer>


        </>
    )
}

export default Kontakt