import React from "react";
import download from '../Images/download.jpg'
import fillin from '../Images/fillin.jpg'
import match from '../Images/match.jpg'
import css from '../Components/How.css'
import free from '../Images/freeicon.png'
import skrivikon from '../Images/skrivikon.png'
import chatikon from '../Images/chatikon.png'
import laddanerikon from '../Images/laddanerikon.png'

function How() {
    return (
        <section className="how-bg">
            <div className="how-topic">
                <h1>Hur fungerar Settlr?</h1>
            </div>
            <div className="how-flex">
                <div className="how1">

                    <img src={free}></img>
                    <h3>Ladda ner appen
                    </h3>
                    <p>Ladda ned Settlr®-appen och logga in. Appen finns tillgänglig för nedladdning från App Store och Google Play Store.
                    </p>
                </div>
                <div className="how2">

                    <img src={skrivikon}></img>
                    <h3>Fyll i vad du söker
                    </h3>
                    <p>För att förstå dina behov bättre ställer vi några frågor om din annons, dina kriterier och dina preferenser.
                    </p>
                </div>
                <div className="how3">

                    <img src={chatikon}></img>
                    <h3>Matcha & börja chatta
                    </h3>
                    <p>Låt våra algoritmer göra grovjobbet åt dig, när båda parterna har visat intresse så är det dags att börja chatta!
                    </p>
                </div>
            </div>
        </section>
    )

}

export default How