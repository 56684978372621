import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import mitthemlogo from '../Images/Mitthemlogga.svg'
import { Link } from 'react-router-dom';
import css from './Navbar.css'
import './Navbar.css'
import ScrollToTop from '../Components/ScrollToTop';

const Navbar = () => {

    var currentPage = document.location.href.substring(document.location.href.lastIndexOf("/") + 1, document.location.href.length);


    const [color, setColor] = useState(false)
    const changeColor = () => {
        if (window.scrollY >= 90) {
            setColor(true)
        }
        else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)


    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)
    const closeMenu = () => setClick(false)

    return (
        <div className={color ? 'header header-bg' : 'header'}>
            <nav className='navbar'>
                <a href='/' className='logo' >
                    <img src={mitthemlogo} alt='logo'></img>

                </a>

                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: 'white' }} />)
                        : (<FaBars size={30} style={{ color: 'white' }} />)}

                </div>

                <ul className={click ? "nav-menu active" : "nav-menu"}>

                    <li className='nav-item'>
                        <Link to='/' onClick={closeMenu}>Hem</Link>
                    </li>

                    <li className='nav-item'>
                        <Link to='/lösning' onClick={closeMenu}>Lösning</Link>
                    </li>

                    <li className='nav-item'>
                        <Link to='/omoss' onClick={closeMenu}>Om oss</Link>
                    </li>

                    <li className='nav-item'>
                        <Link to='/kontakt' onClick={closeMenu}>Kontakt</Link>

                    </li>

                </ul>
                <ScrollToTop />
            </nav>
        </div>
    )
}

export default Navbar
