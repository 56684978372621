import React from "react";
import css from '../Omoss/Sustainable.css'
import Ungoals from '../Images/Ungoals.jpg'
import Ungoal75 from '../Images/ungoal75.jpg'
import Ungoal10 from '../Images/Ungoal10.png'
import Ungoal11 from '../Images/ungoal11.png'

function Sustainable() {
    return (
        <div className="Sus-container">
            <div className="text-container">
                <h1>Hållbarhetsmål.</h1>
                <p>Settlr arbetar aktivt med frågor kring hållbara städer/samhällen och kvalitetsutbildning. Vi använder hållbar utvecklingsmålen från agenda 2030 som grund för hur vi arbetar med dessa frågor. Här är de mål som vi siktar på att få störst inverkan på under det kommande decenniet. Läs mer om målen för hållbar utveckling här.

                </p>
            </div>

            <div className="Sus-img">
                <img className="Sus-img-goal" src={Ungoal75}></img>
                <div className="Sus-img-goals">
                    <img src={Ungoal10}></img>
                    <img src={Ungoal11}></img>
                </div>

            </div>
        </div>
    )

}

export default Sustainable