import React from "react";
import css from '../Solutions/SolutionMain.css'
import bostadssökare from '../Images/Sekundärbild.png'
import förbeställappstore from '../Images/Förbeställappstore.svg'

function SolutionMain() {
    return (
        <section>
            <div className="Nav-bg"></div>
            <div className="Solutionmain-container">
                <div className="Solution-text">
                    <h1>Innovativ matchmaking för en sömlös hyresupplevelse.</h1>
                    <p>Vi har lyckats skapa den enklaste och smidigaste plattformen för att både hyra och hyra ut bostäder. Vår automatiserade process gör att du kan känna dig trygg från att du matchat tills att kontraktet löpt ut. Vi vill hjälpa dig att hitta ditt nästa hem.</p>
                    <div className="Solution-logo">
                        <a className="Solution-apple" href=""><button><img src={förbeställappstore}></img></button></a>

                    </div>
                </div>

                <div className="Solution-img">
                    <img src={bostadssökare}></img>
                </div>




            </div>


        </section>
    )
}
export default SolutionMain