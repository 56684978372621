import React from "react";
import css from './MainAbout.css'
import David from '../Images/David.jpg'
import Sebastian from '../Images/Sebastian.jpg'
import Victor1 from '../Images/victor1.jpg'

function MainAbout() {
    return (
        <section>
            <div className="Nav-bg"></div>
            <div className="Team-container">
                <div className="Team-text">
                    <h1>Vilka vi är.</h1>
                    <p>Digital tjänsteutvecklare, UX/UI-designer, Fullstack-utvecklare.</p>
                </div>
                <div className="Team-images">
                    <div className="David">
                        <img src={David}></img>
                        <div className="David1">
                            <h1>David Klitkou.</h1>
                            <h3>David är en teknikentusiast och studerar för närvarande en kandidatexamen i Digital Service Innovation. Hans passion är att använda datadriven design för att maximera hållbarhet och kundnöjdhet.</h3>
                        </div>
                    </div>
                    <div className="Sebastian">
                        <img src={Sebastian}></img>
                        <div className="Sebastian1">
                            <h1>Sebastian Castwall.</h1>
                            <h3>Sebastian studerar digital tjänsteutveckling och har en bakgrund inom ekonomi. Han gillar innovativt tänkande och tycker om kreativ problemlösning där AI är något av det senaste intressena. </h3>
                        </div>
                    </div>
                    <div className="Victor">
                        <img src={Victor1}></img>
                        <div className="Victor1">
                            <h1>Victor Panom.</h1>
                            <h3>Victor är en fullstack-utvecklare med över ett decenniums erfarenhet samt har en bakgrund som DevOps. Han strävar efter excellens i varje aspekt av sitt arbete för att leverera kod av hög kvalitet.</h3>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default MainAbout