
import css from './Main.css'


import Mainpic from '../Images/Mainpic2.jpg'






const Main = () => {



    return (
        <main className='screen' id='top'>
            <section className='hero-bg'>
                <div class="hero-flex">
                    <div className='hero-text'>
                        <h1>Revolutionera hyresmarknaden med skräddarsydda matchningar.

                        </h1>
                        <p className='hero-text-big'>Genom data och algoritmer matchar vi dig med ditt framtida hem. Matchningen baseras på dina och hyresvärdens preferenser för att skapa långsiktiga kontrakt utefter bådas behov. </p>
                    </div>
                    <div className='button-flex'>
                        <p>Att hyra har aldrig varit enklare. Snart tillgänglig! </p>
                        <button className='hero-button'>Lanseras snart</button>

                    </div>

                </div>
                <div className='hero-img'><img src={Mainpic}></img></div>

            </section>
        </main>
    )
}

export default Main

