import React, { useEffect, useRef } from 'react';
import css from '../Contact/MainContact.css'
import ltukarta from '../Images/ltukarta.png'

function MainContact() {


    return (
        <section>
            <div className="Nav-bg"></div>
            <div className='contact-container'>
                <div className='contact-textmap'><h1>Kontakta Oss.</h1>

                    <div className='contact-mapemail'>
                        <img className='contact-img' src={ltukarta}></img>

                        <div className='contact-text'>
                            <div className='contact-text2'>
                                <p>Luleå Tekniska Universitet</p>
                            </div>
                            <p>Email:</p>
                            <a href="mailto:info@settlr.nu"><u>info@settlr.nu</u></a>
                            <p>Phone</p>
                            <a href="tel:+46768133751"><u>+46 (0)76-813 37 51</u></a>



                        </div>

                    </div>
                    <div className='contact-text1'>
                        <p>Luleå Tekniska Universitet</p>
                    </div>



                </div>

            </div >

        </section >
    );
}

export default MainContact;
